import React from "react";

import { useStyletron } from "baseui";
import { Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import MspLogo from "../images/msp-logo.svg";
import { SMALL } from "../themes/media-queries";

const Logo = () => {
  const [css] = useStyletron();

  const titleContainerStyles = css({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "left",
    padding: "20px 0",
  });

  const logoStyles = css({
    width: "70px",
    height: "70px",
    [SMALL]: {
      width: "45px",
      height: "45px",
    },
    padding: "0 20px",
  });

  const titleStyles = css({
    [SMALL]: {
      fontSize: "15px",
      lineHeight: "15px",
      paddingBottom: "5px",
    },
  });

  return (
    <div className={titleContainerStyles}>
      <StyledLink
        href="https://corpmsp.ru/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MspLogo title="Корпорация МСП" className={logoStyles} />
      </StyledLink>
      <Label1 className={titleStyles}>Корпорация МСП</Label1>
    </div>
  );
};

export default Logo;
