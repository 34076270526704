import React from "react";

import { useStyletron } from "baseui";
import { Card, StyledBody } from "baseui/card";
import { Paragraph2, Label3 } from "baseui/typography";
import { Heading } from "baseui/heading";

import Link from "../components/universal-link";

export default ({ title, description, date, link, tag }) => {
  const [css, theme] = useStyletron();
  let borderAccentColor, borderAccentColorHover;
  switch (tag) {
    case "МКК":
      borderAccentColor = theme.colors.primary500;
      borderAccentColorHover = theme.colors.primary;
      break;
    case "ФРП":
      borderAccentColor = theme.colors.negative300;
      borderAccentColorHover = theme.colors.negative400;
      break;
    default:
      borderAccentColor = theme.colors.mono600;
      borderAccentColorHover = theme.colors.mono700;
  }
  return (
    <Link to={link}>
      <Card
        className={css({
          boxShadow: theme.lighting.shadow400,
          marginBottom: "30px",
        })}
        overrides={{
          Root: {
            style: {
              borderTopWidth: 0,
              borderBottomWidth: 0,
              borderRightWidth: 0,
              padding: "10px 0",
              borderLeftWidth: "8px",
              borderLeftStyle: "solid",
              borderLeftColor: borderAccentColor,
              ":hover": {
                borderLeftColor: borderAccentColorHover,
              },
              borderRadius: 0,
            },
          },
        }}
      >
        <StyledBody>
          <Label3>{date}</Label3>
          <Heading styleLevel={5} marginTop="10px">
            {title}
          </Heading>
          <Paragraph2>{description}</Paragraph2>
        </StyledBody>
      </Card>
    </Link>
  );
};
