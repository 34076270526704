import React from "react";

import { Link } from "gatsby";
import { useStyletron } from "baseui";
import { StyledLink } from "baseui/link";

export default ({ to, children }) => {
  const [css, theme] = useStyletron();

  const linkStyle = css({
    textDecoration: "none !important",
    ":focus": {
      outline: `3px solid ${theme.colors.accent}`,
    },
    ":active": {
      outline: `none`,
    },
  });
  return to[0] === "/" ? (
    <Link className={linkStyle} to={to}>
      {children}
    </Link>
  ) : (
    <StyledLink
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={linkStyle}
    >
      {children}
    </StyledLink>
  );
};
