import React from "react";

import { Heading, HeadingLevel } from "baseui/heading";

import NewsCard from "./newsCard";

const News = ({ data: { nodes } }) => {
  const listNews = nodes.map(news => {
    const {
      id,
      excerpt,
      frontmatter: { title, date, tag },
      fields: { slug },
    } = news;

    return (
      <NewsCard
        key={id.toString()}
        date={date}
        title={title}
        description={excerpt}
        link={slug}
        tag={tag ? tag.toUpperCase() : "МКК"}
      />
    );
  });
  return (
    <HeadingLevel>
      <Heading>Новости</Heading>
      <HeadingLevel>{listNews}</HeadingLevel>
    </HeadingLevel>
  );
};

export default News;
